
            @import "@/styles/abstracts/all.scss";
          

.c-input-label {
  font-weight: 400;
  font-family: $title-font-stack;
  display: block;
  margin-bottom: 8px;
}
