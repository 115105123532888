
            @import "@/styles/abstracts/all.scss";
          

.c-modal {
  animation: fadeIn 0.4s ease 0s 1;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    animation: 0.4s ease 0s 1 slideInFromBottom;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
