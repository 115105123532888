
            @import "@/styles/abstracts/all.scss";
          

.l-tabs {
  display: flex;
  align-items: center;
  row-gap: 16px;
  column-gap: 16px;
  overflow-x: auto;
  padding-top: 1px;
  padding-bottom: 2px;

  @include tablet {
    flex-wrap: wrap;
    overflow-x: auto;

    @include scrollbar-styling;
  }

  & > * {
    flex: 0 0 auto;
    margin-bottom: 12px;
    margin-top: 12px;

    @include tablet {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
