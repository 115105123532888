
            @import "@/styles/abstracts/all.scss";
          

#search-anker-mobile {
  @include tablet-landscape {
    display: none;
  }
}

.search-bar-mobile {
  @include tablet-landscape {
    display: none;
  }

  &__wrapper {
    @include tablet-landscape {
      display: none;
    }
  }
  color: inherit;
  &__wrapper {
    position: fixed;
    background-color: white;
    left: 0;
    width: 100vw;
    height: 80px;
    opacity: 0;
    z-index: 19;
    top: 0;
    pointer-events: none;
    transform: translateY(-105px);
    transition: transform 0.5s ease-out;
    &--moves-down {
      transform: translateY(0);
    }
    &--open {
      opacity: 1;
      pointer-events: all;
      top: 85px;
    }
    &--temp-hidden {
      transition: opacity 0.3s ease-out;
      transform: translateY(0);
      top: 85px;
      pointer-events: none;
      opacity: 0;
    }
  }
  &__container {
    margin: 0 auto;
    height: 80px;
    @include container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-top: 1px solid $grey-very-light;
  }
  &__input {
    font-size: 28px;
    line-height: 40px;
    width: 100%;
    border: 0;
    font-weight: 500;
    font-family: $title-font-stack;
    &::placeholder {
      color: #ccc;
      font-weight: 500;
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
  &__icon {
    width: 19px;
    height: 19px;
  }

  &__button {
    background-color: $orange;
    border: 1px solid $orange;
    color: white;
    transition: all 0.1s ease-out;
    width: 60px;
    height: 48px;
    padding: 0;
    &:disabled {
      color: black;
      background-color: transparent;
      border: 1px solid $black;
    }
    &--text {
      color: inherit;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 70px;
      height: 70px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  &__underlay {
    @include tablet-landscape {
      display: none;
    }
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: backdrop-filter 0.5s ease-out;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    pointer-events: none;
    &--open {
      pointer-events: all;
    }
    &--moves-down {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }
}
