.expandable-carrousel {
    &__slider {
        height: auto;
        margin-top: 32px;
    }

    .swiper-slide {
        height: auto;
        width: auto;
        display: flex;
    }

    .swiper-container {
        position: relative;
        padding-bottom: 4em;
    }

    .swiper-scrollbar {
        z-index: 3;
        background-color: #ECEBEB;
        height: 4px !important;
    }

    .swiper-scrollbar-drag {
        margin-top: 2rem;
        background-color: $purple-main;
        height: 4px;
    }

    &--green-main {
        .swiper-scrollbar-drag {
            background-color: $green-main;
        }
    }
}

.expandable-card {
    background-color: $white;
    border-radius: $border-radius-standard;
    border: 1px solid $purple-light;
    width: 325px;
    min-height: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) width .5s, cubic-bezier(0.175, 0.885, 0.32, 1.275) height .5s;


    @include mobile {
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) width .75s, cubic-bezier(0.175, 0.885, 0.32, 1.275) height .75s;
    }

    &__read-less-btn{
        display: none;
    }

    &--expanded {
        width: 650px;

        @include mobile {
            width: calc(100vw - 40px);
        }
    }

    &--expanded &__subtitle {
        width: 594px;

        display: -webkit-box;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
        overflow: unset;
        text-overflow: unset;

        @include mobile {
            width: calc(100vw - 80px);
        }
    }

    &--expanded &__read-more-btn{
        display: none;
    }

    &--expanded &__read-less-btn{
        display: block;
    }

    &__text {
        padding: 1.75rem;

        @include mobile {
            padding: 1.25rem;
        }
    }

    &__number {
        position: absolute;
        bottom: 19px;
        right: 0;

        font-weight: 600;
        font-size: 120px;
        line-height: 40px;
        letter-spacing: 0;
        opacity: 0.15;
    }

    &__subtitle {
        font-weight: 300;
        font-size: 0.875rem;
        width: 270px;
        white-space: normal;
        margin:8px 0 16px;

        &--limit {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__image {
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
    }

    &--purple-200 {
        background-color: $purple-200;
        border-color: $purple-200;
    }

    &--purple-200 &__number {
        color: $purple-main;
    }

    &--green-200 {
        background-color: $green-200;
        border-color: $green-200;
    }

    &--green-200 &__number {
        color: $green-main;
    }
}