
            @import "@/styles/abstracts/all.scss";
          

.c-calculation-results {
  display: grid;
  grid-template-columns: 1fr;

  @include tablet-landscape {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
  }

  &__header {
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;

    @include tablet-landscape {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include widescreen {
      padding-left: 64px;
      padding-right: 64px;
    }

    &--basic {
      background-color: $green-light;
      margin-top: 40px;

      @include tablet-landscape {
        grid-column: 1 / span 2;
      }
    }

    &--complete {
      background-color: $purple-light;
      grid-row: 4 / span 1;
      margin-top: 100px;

      @include tablet-landscape {
        grid-row: 1 / span 1;
        grid-column: 3 / span 2;
        margin-top: 40px;
      }
    }
  }

  &__header-image {
    margin-top: -40px;
    margin-bottom: 16px;
  }

  &__package {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
  }
  &__helper-text {
    @include full-grid;
    .c-card-solid {
      grid-column: 4 / span 8;
    }
    margin-bottom: 24px;
  }

  &__package-top {
    height: 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: flex-start;
  }

  &__package-bottom {
    border-left: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__package--directFocus &__package-top {
    background-color: $green-600;
  }

  &__package--directFlex &__package-top {
    background-color: $green-400;
  }

  &__package--klokFocus &__package-top {
    background-color: $purple-600;
  }

  &__package--klokFlex &__package-top {
    background-color: $purple-400;
  }

  &__package-header {
    padding-right: 16px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    padding-top: 16px;
    margin-bottom: 16px;
  }

  &__package-total-price {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    background-color: $grey-light;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  &__package-pretitle {
    font-family: $title-font-stack;
    font-size: 18px;
    margin-bottom: 2px;
  }

  &__package-title {
    font-size: 24px;
  }

  &__package-usps {
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  &__package-cost {
    margin-top: auto;
  }

  &__package-cost-item {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid $grey-light;
    display: flex;
    justify-content: space-between;
    column-gap: 8px;

    &--small {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &__package--current {
    grid-row: 1 / span 1;
    border: 4px solid $green;
    border-radius: 6px;

    @include tablet-landscape {
      grid-row: auto;
    }
  }
  &__package--selected {
    grid-row: 1fr;
    border: 4px solid $orange;
    border-radius: 6px;

    @include tablet-landscape {
      grid-row: auto;
    }
  }
  &__package--selected &__package-top,
  &__package--current &__package-top {
    border: 0px;
    border-radius: 0px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &__package--selected &__package-bottom,
  &__package--current &__package-bottom {
    border: 0px;
    border-radius: 2px;
  }
  &__package-discount {
    margin-top: -16px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    padding: 6px 0;
  }

  &__package--selected &__package-discount {
    background-color: $orange;
    color: $white;
  }
  &__package--current &__package-label {
    background-color: $green;
  }
  &__package--selected &__package-label {
    background-color: $orange;
  }

  &__package--selected &__package-label,
  &__package--current &__package-label {
    display: block;
    color: $white;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $micro-font-stack;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
  }

  &__package--greyed-out &__package-top {
    background-color: $grey-light;
  }

  &__package--greyed-out {
    color: $grey;
    grid-row: 4 / span 1;

    @include tablet-landscape {
      grid-row: auto;
    }
  }
}
