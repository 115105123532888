
            @import "@/styles/abstracts/all.scss";
          

.c-radios {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__input {
    display: none;
  }

  &__checkmark {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $grey;
    border-radius: 50%;
    margin-right: 0.5rem;
    order: -1;

    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $green;
    }
  }

  &__input:checked + &__checkmark {
    border-color: $green;
  }

  &__input:checked + &__checkmark::after {
    display: block;
  }
}
