
            @import "@/styles/abstracts/all.scss";
          

.c-multi-select {
  position: relative;
  height: 44px;
  border: $border-standard-2023;
  border-radius: 6px;
  min-width: 200px;

  &__selected {
    @include basic-reset;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: $title-font-stack;
    font-weight: 200;
  }

  &__icon {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &__options {
    display: none;
    z-index: calc($navbar-index - 1);
    position: absolute;
    bottom: -8px;
    left: 0;
    transform: translateY(100%);
    border: $border-standard-2023;
    border-radius: 6px;
    background-color: $white;
    padding: 16px;
    min-width: max-content;
    width: 100%;
  }

  &--open &__options {
    display: block;
  }
}
