.section-footer {
    overflow: hidden;
    max-width: $jumbotron;
    padding-left: $section-outer-padding--mobile;
    padding-right: $section-outer-padding--mobile;
    margin-top: 64px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    background-image: image("leaf-right--diagonal-white.svg");
    background-repeat: no-repeat;
    background-size: calc($jumbotron + 50px) auto;
    background-position: 20% 0%;

    &--grey-leaf {
        background-image: image("leaf-right--diagonal.svg");
    }

    @include tablet {
        padding-left: 0;
        padding-right: 0;
    }

    @include tablet-landscape {
        margin-top: 120px;
    }

    @include widescreen {
        background-position: calc(20% + 100px) 0%;
    }

    &--grey {
        background-color: $grey-very-light;
    }
}

.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include tablet-landscape {
        min-height: 300px;
        align-items: flex-start;
    }

    &__column {
        @include flex-percentage(1, 1);

        @include tablet-landscape {
            @include flex-percentage(4, 1);
        }

        &--logo,
        &--extras {
            padding-bottom: $padding-size * 3;
            @include tablet-landscape {
                padding-bottom: 0px;
            }
        }

        &--main {
            @include flex-percentage(2, 1);

            @include tablet-landscape {
                @include flex-percentage(4, 1);
            }
        }

        &--bottom {
            padding-bottom: $padding-size * 2;
            @include tablet-landscape {
                align-self: flex-end;
                @include flex-percentage(1, 1);

                padding-top: $padding-size * 2;
            }
        }
    }

    &__heading {
        padding-top: $padding-size;
        padding-bottom: 4px;
    }

    &__item {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &__subscribe {
        margin-top: $padding-size * 2;
        a {
            @extend .link;
            @extend .link--orange;
            @extend .link--decorated;
        }
    }

    &__bar {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__bar-start {
        @include tablet {
            display: flex;
        }
    }

    &__bar-end {
        display: flex;
        justify-content: space-between;
        padding-top: $padding-size;
        gap: $padding-size;

        @include tablet {
            padding-top: 0;
        }

        & > li {
            display: block;
        }
    }

    &__bar-end-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 24px;

        @include tablet {
            padding-top: 0;

            & > *:not(:last-child) {
                margin-right: $padding-size;
            }
        }
    }
}

.jobs-footer {
    margin-top: 0;

    @include tablet-landscape {
        background-size: 138% auto;
        background-position: -125% 25%;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        margin: 3rem 0;
        padding: 2rem 0;
        gap: 2rem;

        @include tablet {
            padding: 3rem 2rem 1rem 2rem;
        }
    }

    &__column {
        display: flex;
        gap: 1rem;
        grid-column: 1 / -1;
        flex-direction: column;

        @include tablet-landscape {
            grid-column: span 3;
        }
    }

    &__links-list {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
    }

    &__title {
        font-weight: 600;
        font-size: 1.125rem;
    }

    &__social-icons {
        display: flex;
        gap: 0.8rem;
    }

    &__bottom {
        grid-column: 1 / -1;
        grid-row: 2;
        margin-top: 1rem;
        display: flex;
        font-size: 0.8rem;
        flex-wrap: wrap;
        flex-direction: column;

        @include tablet-landscape {
            flex-direction: row;
            justify-content: end;
        }
    }

    &__bar {
        display: none;

        @include tablet-landscape {
            display: block;

            margin: 0 0.3rem;
        }
    }
}

.new-footer {
    background-color: $green-light;
    overflow: hidden;
    padding: 0;
    margin-top: $new-footer-margin-top;
    max-width: 100dvw;
    overflow: hidden;

    &__container {
        @include container;
        display: flex;
        position: relative;
        overflow: visible;
        flex-wrap: wrap;
        padding: 0 24px;
    }

    &__links {
        display: flex;
        gap: 50px;
        position: relative;
        color: $white;
        flex-wrap: wrap;
        overflow-y: visible;
        padding: 50px 0;

        &::before {
            background-color: $green-dark;
            position: absolute;
            content: "";
            left: -25vw;
            height: 100vh;
            width: 150vw;
            top: 100vw;
            @include tablet-landscape {
                right: 0;
                top: 0;
                left: auto;
                width: auto;
                left: -50vw;
                height: 500px;
            }
        }
    }

    &__column {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: visible;
    }

    &__link {
        color: $white;
        overflow-y: visible;
        display: block;
        text-decoration: none;
        width: fit-content;
        &:hover {
            color: $white;
        }
        @include tablet-landscape {
            @include text-link($white);

            @include on-event {
                @include text-link-hover($white);
            }
        }
    }

    &__logo {
        position: relative;
        width: 100%;

        @include tablet-landscape {
            width: auto;
        }
    }

    &__leaf {
        position: absolute;
        width: 100%;
        overflow: visible;
        top: 0;
        left: 0;

        svg {
            top: 0;
            height: auto;
            position: absolute;
            overflow: visible;

            width: 250vw;
            left: -140vw;

            @include tablet-landscape {
                width: 980px;
                left: -198px;
            }
        }
    }

    &__socials {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: start;
        width: 100%;
        padding-top: 110px;
        column-gap: 20px;

        @include tablet-landscape {
            padding-top: 50px;
            justify-content: end;
            width: auto;
        }
    }

    &__social {
        color: $green-200;

        &:hover {
            color: $green-200;
        }

        svg {
            height: 30px;
            width: auto;
            @include button-hover;
        }

        @include tablet-landscape {
            color: $green-main;

            &:hover {
                color: $green-800;
            }
        }
    }

    &__bottom {
        position: relative;
        justify-content: space-between;
        display: flex;
        width: 100%;
        background-color: $green-dark;
        padding: 20px 0px;
        flex-wrap: wrap;
    }
    &__bottom-container {
        @include container;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        padding-bottom: 20px;
        @include tablet-landscape {
            gap: 40px;
        }
    }

    &__divider {
        position: relative;
        width: 100%;
        mix-blend-mode: difference;
        height: 1px;
        margin-bottom: 20px;
        margin-top: 20px;
        background-color: $green-light;
    }

    &__copyright {
        color: $white;
        position: relative;
        z-index: 4;
    }
    &__copyright,
    &__bottom-link {
        font-size: 12px;
        line-height: 1.6;
    }

    &__bottom-links,
    &__bottom-links ul {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 32px;
        position: relative;
        z-index: 4;

        @include tablet-landscape {
            order: 1;
        }
    }
    &__bottom-links ul li {
        display: flex;
    }

    &__bottom-link,
    &__bottom-link ul {
        color: $white;
        text-decoration: none;
        &:hover {
            color: white;
        }
        @include tablet-landscape {
            @include text-link($white);

            @include on-event {
                @include text-link-hover($white);
            }
        }
    }
    &__bottom-link ul li {
        display: flex;
    }
}

.c-events-footer {
    @include full-grid-with-column-gap;

    position: relative;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-top: 80px;

    & > * {
        grid-column: main-start / main-end;
    }

    &::after {
        position: absolute;
        content: "";
        background-image: svg("footer/footer-shape-mobile.svg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;

        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: -1;

        @include tablet {
            background-image: svg("footer/footer-shape-desktop.svg");
        }
    }

    &__logo {
        margin-bottom: 32px;

        @include tablet-landscape {
            grid-column: main-start / span 2;
        }
    }

    &__links {
        display: grid;
        margin-bottom: 32px;
        grid-auto-flow: dense;

        @include tablet {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 34px;
        }

        @include tablet-landscape {
            grid-column: 4 / span 6;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
    }

    &__link {
        position: relative;
        text-decoration: none;
        font-size: 16px;
        font-family: $title-font-stack;
        // font-weight: 400;
        padding-bottom: 8px;
        padding-top: 8px;
        border-bottom: 1px solid $grey-very-light;

        &:hover {
            color: $color-tertiary !important;
        }

        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            width: 8px;
            height: 10px;
            margin-bottom: 14px;
            background-color: $color-quartary;
            mask-image: icon("chevron-right.svg");
            mask-size: contain;
        }
    }

    &__column--3 &__link:last-child {
        border-bottom: transparent 1px solid;
    }

    @include tablet {
        &__column--1 &__link:last-child,
        &__column--2 &__link:last-child {
            border-bottom: transparent 1px solid;
        }
    }

    &__extra {
        margin-bottom: 32px;

        @include tablet-landscape {
            grid-column: 10 / main-end;
        }
    }

    &__bar {
        display: flex;
        flex-wrap: wrap;

        @include tablet-landscape {
            grid-column: 4 / main-end;
        }
    }

    &__bar-item {
        display: flex;
        align-items: center;

        &:not(:last-of-type)::after {
            content: "-";
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    &__bar-link {
      font-size: 14px;
      text-decoration: none;

      &--link {
          &:hover {
              color: $green;
          }
      }
    }

    &__subscribe-content {
        margin-bottom: 24px;

        a {
            @extend .link;
            @extend .link--orange;
        }
    }

    &__extra-title {
        margin-bottom: 8px;
    }
}
