.navigation-wrapper {
    margin-top: $navigation-mobile-height;

    @include tablet-landscape {
        margin-top: $navigation-height;
    }
}

.top-navigation {
    position: relative;
    display: none;
    height: $navigation-top-height;
    background-color: $green-200;
    z-index: $z-index-navigation;

    @include tablet-landscape {
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
        transition: top $animation-speed;
        &--scrolled {
            top: -$navigation-top-height;
        }
        &--scroll-up {
            top: 0;
        }
    }

    &--transparent:not(.top-navigation--scrolled) {
        background: transparent;
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: $navigation-top-height;
            background: rgba(0, 0, 0, 0.5);
        }

        .top-navigation__menu-link {
            color: $white;
            position: relative;
            z-index: 3;
            &:hover {
                color: $white;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: $u-32;
        @extend .container;
        width: 100%;
    }
    &__menu-item {
        position: relative;
        z-index: 6;
    }

    &__menu-link {
        display: flex;
        align-items: center;
        gap: $u-8;

        padding-inline: 12px;
        padding: $u-8 0;

        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;

        transition: color 0.1s ease-in;

        &:hover {
            color: $grey-dark;
        }
    }

    &__menu-link-icon {
        height: 14px;
        width: auto;
    }
}

.navigation {
    display: none;
    height: $navigation-main-height;
    background-color: $white;
    z-index: $z-index-navigation;

    transition: background-color $animation-speed;

    @include tablet-landscape {
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
        transition: top $animation-speed;
        &.navigation--scroll-up {
            top: $navigation-top-height;
        }
    }
    &--top {
        top: $navigation-top-height;
    }

    &--transparent:not(.navigation--scrolled) {
        background-color: transparent;
        // position: relative;
        top: $navigation-top-height;
        &::after {
            background: rgb(0, 0, 0);
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.45) 100%
            );
        }
        .navigation__logo-wrapper--white {
            path,
            polygon {
                fill: white;
            }
        }
        .navigation__menu-item-link,
        .navigation__menu-right-link {
            color: $white;
            &:has(+ .navigation__menu-item-dropdown:hover) {
                color: $white;
            }
        }
        .c-switcher-links__language-switcher-icon {
            color: $white;
        }
    }
    &--transparent {
        background-color: transparent;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: $navigation-main-height;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: white;
            transition: background $animation-speed;
        }
    }
    &__logo-wrapper {
        path,
        polygon {
            transition: fill $animation-speed;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @extend .container;
        width: 100%;
        padding-block: 12px;
    }

    &__menu-left,
    &__menu-right {
        flex: 1 1 0px;
    }

    &__menu-left {
        display: flex;
    }

    &__menu-item {
        position: relative;

        &:hover .navigation__menu-item-dropdown {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    &__menu-item-link {
        padding-inline: 12px;
        padding-block: $u-8;

        font-size: 16px;
        font-weight: 600;
        text-decoration: none;

        transition: color $animation-speed;

        &:hover,
        &:has(+ .navigation__menu-item-dropdown:hover) {
            color: $green;
        }
    }

    &__menu-item-dropdown {
        position: absolute;
        top: calc(100% + 6px);
        left: 50%;
        translate: -50% 0;
        width: max-content;
        max-width: 250px;

        padding: $u-8;

        display: flex;
        flex-direction: column;
        gap: 8px;

        background-color: $white;
        border: 1px solid $grey-light;
        border-radius: 6px;

        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: 0.2s cubic-bezier(0, 0, 0.08, 0.99);
        transition-property: opacity, transform;
    }
    &__menu-item-dropdown-item {
        padding: 8px 9px;
        &:first-child {
            background-color: $green-100;
            border-radius: 4px;
            margin: 0 !important;
            position: relative;

            .navigation__menu-item-dropdown-link {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }

            .navigation__menu-item-dropdown-link-arrow {
                opacity: 1;
                color: $green;
            }
            .navigation__menu-item-dropdown-link:hover {
                color: $black;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                width: calc(100% - 16px);
                height: 1px;
                margin: 0;
                border: none;
                bottom: -9px;
                background-color: $grey;
            }
        }
    }

    &__menu-item-dropdown-link {
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;

        transition: color 0.1s ease-in;
        &:hover {
            color: $green;
        }
    }

    .navigation__logo-white {
        display: none;
    }

    &__menu-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__menu-right-link {
        display: flex;
        align-items: center;
        gap: $u-8;

        padding-inline: 12px;
        padding-block: $u-8;

        font-size: 16px;
        font-weight: 400;
        border: none;
        text-decoration: none;

        background: none;
        border: none;
        cursor: pointer;

        transition: color 0.1s ease-in;

        &:hover {
            color: $green;
        }
    }

    &__menu-right-link-icon {
        width: $u-16;
        height: $u-16;
    }
}

.navigation-mobile {
    position: fixed;
    width: 100%;
    top: 0;
    height: $navigation-mobile-height;
    z-index: $navigation-mobile-z-index;
    background-color: $white;

    padding: $u-8;

    transition: background-color $animation-speed;

    @include tablet-landscape {
        display: none;
    }

    &--transparent:not(&--scrolled) {
        background-color: transparent;
        &::after {
            background: rgb(0, 0, 0);
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.45) 100%
            );
        }
        .temp-search-bar {
            background-color: transparent;
        }
        .navigation-mobile__menu-right-link-icon {
            color: $white;
        }
        .navigation-mobile__logo-wrapper--white {
            path,
            polygon {
                fill: white;
            }
        }

        .navigation-mobile__hamburger {
            .line {
                stroke: #ffffff;
            }
        }
        .navigation-mobile__search-container {
            color: #ffffff;
            svg {
                fill: white;
            }
        }
    }
    &--transparent {
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: $navigation-mobile-height;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: white;
            transition: background $animation-speed;
        }
    }

    &__container {
        @extend .container;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: $navigation-mobile-z-index;

        height: 100%;
    }
    &__logo-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        path,
        polygon {
            transition: fill $animation-speed;
        }
    }

    &__logo,
    &__logo-white {
        height: 54px;
    }

    &__logo-white {
        display: none;
    }
    &__logo,
    &__items {
        position: relative;
        z-index: 11;
    }

    &__items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $u-8;
    }

    &__hamburger {
        $hamburger-speed: 300ms;
        background-color: transparent;
        border: 0;
        padding: 0;
        padding-top: 5px;
        line-height: 1;
        .ham {
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            transition: transform $hamburger-speed;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .hamRotate.active {
            transform: rotate(45deg);
        }
        .hamRotate180.active {
            transform: rotate(180deg);
        }
        .line {
            fill: none;
            transition: stroke-dasharray $hamburger-speed,
                stroke-dashoffset $hamburger-speed;
            stroke: #000;
            stroke-width: 5;
            stroke-linecap: round;
        }
        .ham8 .top {
            stroke-dasharray: 40.1 160;
        }
        .ham8 .middle {
            stroke-dasharray: 40 142;
            transform-origin: 50%;
            transition: transform $hamburger-speed;
        }
        .ham8 .bottom {
            stroke-dasharray: 40.1 85;
            transform-origin: 50%;
            transition: transform $hamburger-speed,
                stroke-dashoffset $hamburger-speed;
        }
        .ham8.active .top {
            stroke-dashoffset: -66px;
        }
        .ham8.active .middle {
            //stroke-dashoffset: -20px;
            transform: rotate(90deg);
        }
        .ham8.active .bottom {
            stroke-dashoffset: -66px;
        }
    }
    &__menu-right-link {
        background: transparent;
        border: 0;
        padding: 0;
        padding-right: 16px;
    }
    &__menu-right-link-icon {
        width: 30px;
        height: 30px;
    }
    &__search-button {
        background-color: transparent;
        border: 0;
    }
    &--jobs {
        padding: 0;
        .navigation-mobile {
            &__bar {
                padding: 8px;
            }
            &__menu-items {
                background: white;
                width: 100%;
                transform: translateX(100%);

                transition: 0.2s ease;
                &--open {
                    transform: translateX(0);
                }
            }
        }
    }
}

.navigation-flyout {
    z-index: 75;
    // z-index: $navigation-mobile-z-index + 8;

    position: fixed;
    top: $navigation-mobile-height;
    left: 0;
    right: 0;
    bottom: 0;

    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;

    background-color: $white;

    overflow-y: auto;

    &--open {
        transform: translateX(0);
    }

    &__language-icon {
        height: 12px;
        width: auto;
    }

    &__top {
        @extend .container;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        padding-block: $u-8;
        padding-inline: 22px;
    }

    &__logo {
        height: 54px;
    }

    &__menu {
        @extend .container;
        padding-inline: 22px;

        & > * {
            border-top: solid 1px #0000001a;

            &:last-child {
                border-bottom: solid 1px #0000001a;
            }
        }
    }

    &__menu-item-dropdown {
        &[open] {
            .navigation-flyout__menu-item-dropdown-trigger {
                margin-bottom: 10px;
            }

            .navigation-flyout__menu-item-icon--down {
                rotate: 180deg;
            }
        }
    }

    &__menu-item-dropdown-trigger {
        transition: margin-bottom 0.2s ease-out;
    }

    &__menu-item,
    &__menu-item-dropdown-trigger {
        display: flex;
        column-gap: 8px;
        align-items: center;

        width: 100%;
        padding-block: $u-16;

        font-size: 21px;
        font-weight: 400;
        text-decoration: none;

        cursor: pointer;
        &::-webkit-details-marker {
            display: none;
        }
    }

    &__menu-item-icon {
        color: $grey-dark;
        width: 12px;
        margin-left: auto;

        &--down {
            rotate: 0deg;

            transition: rotate 0.2s ease-out;
        }
        &--right {
            rotate: 90deg;
        }
    }

    &__menu-item-dropdown-items {
        padding-bottom: $u-16;
    }

    &__menu-item-dropdown-item {
        padding: $u-8;
        a {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;

            text-decoration: none;
        }
        &:first-child {
            background-color: $green-100;
            border-radius: 4px;
        }
    }
    &__menu-item-dropdown-link-arrow {
        color: $green;
    }

    &__menu-item-dropdown-item-icon {
        color: $grey-dark;
    }

    &__sub-menu {
        @extend .container;
        margin-top: $u-24;
        padding-inline: 22px;

        & > * {
            border-bottom: solid 1px #0000001a;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__sub-menu-link {
        display: flex;
        align-items: center;
        gap: $u-16;

        padding: $u-16 0;

        font-size: 21px;
        font-weight: 400;
        border: none;
        text-decoration: none;

        background: none;
        border: none;
        cursor: pointer;
    }

    &__sub-menu-link-icon {
        height: $u-16;
        width: auto;
    }

    &__menu-bottom {
        background-color: $green-200;
    }

    &__menu-bottom-container {
        @extend .container;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: $u-24;

        margin-top: $u-24;
        padding-block: $u-16;
        padding-inline: 22px;
    }

    &__menu-bottom-link {
        text-decoration: none;
    }
    &__menu-bottom-link-icon {
        height: 16px;
        width: auto;
        margin-right: 4px;
    }
}
.c-navigation-jobs {
    height: $navigation-jobs-height;
    -webkit-box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    z-index: 70;
    display: none;
    width: 100%;
    position: fixed;
    top: 0;

    @include tablet-landscape {
        display: flex;
        align-items: center;
    }

    &__bar {
        @include full-grid;
    }
    &__container {
        grid-column: main-start / main-end;
        display: flex;
        justify-content: flex-start;
        align-items: end;
        gap: 40px;

        @include widescreen {
            grid-column: 2 / -2;
        }
    }
    &__menu-items {
        display: flex;
        gap: 24px;
    }
    &__menu-item {
        padding: 0;
    }
    &__menu-item-link {
        padding: 0;
        padding-bottom: 12px;
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: $orange;
        }
    }

    &__right {
        margin-left: auto;
    }
}
.c-navigation-jobs-mobile {
    display: block;
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 70;
    &--active {
        .c-navigation-jobs-mobile {
            &__menu {
                left: 0;
                bottom: 0;
            }
        }
    }

    @include tablet-landscape {
        display: none;
    }
    &__container {
        height: $navigation-mobile-height;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logo {
        height: 54px;
    }
    &__menu {
        position: fixed;
        width: 100%;
        top: 85px;
        background-color: $white;
        left: 100%;
        transition: left 0.2s ease;
    }
}

.searchbar {
    position: relative;
    display: flex;

    &--active {
        .searchbar__input {
            width: 200px;
            padding: 4px 8px;
            border: 1px solid $grey;

            &--full-width {
                width: 100% !important;
            }
        }
    }

    &--hide-mobile {
        @include until-mobile {
            display: none;
        }
    }

    &__input {
        width: 0px;
        padding: 0px;

        background-color: #ffffff60;
        border: none;
        border-radius: 4px !important;
        outline: none;

        transition: 0.2s ease-out;
        transition-property: width, padding;

        &:focus {
            border-width: 2px;
        }
    }

    &__content {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        right: 0;

        padding: 8px;

        background-color: $white;
        border: 1px solid $grey-light;
        border-radius: 6px;

        pointer-events: none;
        opacity: 0;
        transition: opacity 0.2s ease-out;

        &--active {
            pointer-events: all;
            opacity: 1;
        }
    }
}

.menu-accordion {
    border-top: $border-standard;

    &__trigger {
        height: $navigation-mobile-button-height;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include section-h-sides;

        &--nested {
            &:after {
                content: "+";
                font-size: 24px;
                font-weight: 500;
                height: 32px;
                width: 32px;
                padding-bottom: 4px;
                background-color: $color-grey-normalish;
                color: $black;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &--nested#{&}--open {
            &:after {
                content: "-";
                background-color: $orange;
                color: $white;
            }
        }
    }

    &__content {
        &--bg-grey {
            background-color: $color-grey-lightish;
        }
    }
}

$hamburger-width: 24px;
$hamburger-y-offset: 4px;

.hamburger {
    @include basic-reset;

    position: relative;
    width: $hamburger-width;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 2s;

    &--open {
        justify-content: center;
        align-items: center;
    }

    &__bar {
        opacity: 1;
        transition: all 0.1s;
        width: $hamburger-width;
        height: 3px;
        background-color: $black;

        transition: background-color 0.2s ease-in-out;
    }

    &--open &__bar--1 {
        transform: rotate(-45deg) translateY($hamburger-y-offset);
    }

    &--open &__bar--2 {
        opacity: 0;
    }

    &--open &__bar--3 {
        transform: rotate(45deg) translateY(-$hamburger-y-offset);
    }
}

.c-switcher-links {
    position: relative;
    padding: 0;
    padding-right: 0;

    &__active-item {
        display: flex;
        gap: 10px;
        align-items: center;
        position: relative;
        z-index: 12;
        cursor: pointer;
    }
    &__language-switcher-icon {
        width: 12px;
        &--flag {
            width: 24px;
        }
        &--chevron {
            transition: transform 0.2s ease-in-out;
        }
        &--open {
            transform: rotate(180deg);
        }
    }

    &__items-list {
        background-color: $white;
        padding: 10px;
        position: absolute;
        width: 150px;
        font-size: 16px;
        transform: translate(-50%, -20px);
        opacity: 0;
        transition: all 0.2s ease;
        pointer-events: none;
        border-radius: 6px;

        &--open {
            transform: translate(-50%, 16px);
            opacity: 1;
            z-index: 8;
            pointer-events: all;
        }
    }

    &__item {
        height: $navigation-mobile-button-height;
        display: flex;
        align-items: center;
        border-top: 1px solid $grey-very-light;

        @include tablet-landscape {
            border-top: none;
            height: 34px;
        }
    }

    &__link {
        @include basic-reset;
        display: flex;
        align-items: center;
        column-gap: $u-16;
        text-decoration: none;
        color: $grey-dark;
        opacity: 0.7;

        @include tablet-landscape {
            width: 100%;
            justify-content: space-between;
        }
    }

    &__item--active &__link {
        color: $black;
        opacity: 1;
        pointer-events: none;
    }

    &__item:hover &__link {
        color: $black;
        opacity: 1;
    }

    &__icon {
        width: 24px;
        height: 18px;
        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @include tablet-landscape {
            order: 1;
        }
    }

    &__item--nl &__icon {
        filter: grayscale(0);
        background-image: icon("flags/flag-nl.svg");

        @include tablet-landscape {
            filter: grayscale(1);
        }
    }

    &__item--de &__icon {
        filter: grayscale(0);
        background-image: icon("flags/flag-de.svg");
        @include tablet-landscape {
            filter: grayscale(1);
        }
    }

    &__item--en &__icon {
        filter: grayscale(0);
        background-image: icon("flags/flag-en.svg");
        @include tablet-landscape {
            filter: grayscale(1);
        }
    }

    &__item:active &__icon {
        filter: grayscale(0);
    }

    &__item:hover &__icon {
        filter: grayscale(0);
    }

    &__item--active &__icon {
        filter: grayscale(0);
    }

    &__item--disabled {
        pointer-events: none;
    }

    &__item--disabled &__icon {
        filter: grayscale(1) !important;
    }
}

// EVENTS NAVIGATION
.events-navigation {
    $navbar-height: 92px;
    $ticker-tape__height: 40px;
    $navigation-mobile-height: 70px;
    $navigation-mobile-z-index: 11;
    $navigation-mobile-button-height: 52px;
    $navigation-mobile-border: 1px solid $grey-light;
    outline: none;
    display: none;
    z-index: $z-index-navigation;
    width: 100%;
    background-color: $white;
    height: $navigation-events-height;
    max-width: $widescreen;
    max-width: none;

    @include tablet-landscape {
        display: flex;
    }

    &__container {
        height: 100%;
        display: flex;
        align-items: flex-end;
        width: 100%;
        @include container;
    }

    &__logo-container {
        height: 58px;
        margin-bottom: $padding-bottom-menu-level-one;
    }

    &__logo {
        height: 100%;
    }

    &__menu-items {
        height: 100%;
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__menu-item {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding-left: $spacing-menu-items-level-one;
        padding-right: $spacing-menu-items-level-one;
        padding-bottom: $padding-bottom-menu-level-one;
        color: $black;
    }

    &__menu-items:hover &__menu-item-link {
        color: $color-navigation-link-inactive;
    }

    &__menu-items:hover &__menu-item-link:hover {
        color: $black;
        cursor: pointer;
    }

    &__menu-item-link {
        @include basic-reset;
        font-weight: 700;
        font-size: 14px;
    }

    &__right {
        padding-bottom: $padding-bottom-menu-level-one;
        margin-left: auto;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
}

.events-navigation-mobile {
    $navbar-height: 92px;
    $ticker-tape__height: 40px;
    $navigation-mobile-height: $navigation-events-height-mobile;
    $navigation-mobile-z-index: 11;
    $navigation-mobile-button-height: 52px;
    $navigation-mobile-border: 1px solid $grey-light;

    position: fixed;
    z-index: $navigation-mobile-z-index;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    height: $navigation-mobile-height;

    @include tablet-landscape {
        display: none;
    }

    &__bar {
        background-color: $white;
        width: 100%;
        height: 100%;
        padding-bottom: 12px;
        z-index: $navigation-mobile-z-index + 1;

        @include section-h-sides;
    }

    &__container {
        @include container;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &__logo-container {
        height: 42px;
        margin-right: auto;

        img {
            height: 100%;
        }
    }
    &__logo {
        height: 100%;
    }

    &__menu-item {
        &:not(:last-of-type) {
            padding-right: 20px;
        }
        &--hamburger {
            height: 24px;
            padding-bottom: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__menu {
        position: fixed;
        z-index: $navigation-mobile-z-index;
        margin-top: $navigation-mobile-height;
        transform: translateY(-120%);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $white;
        transition: transform 0.4s ease-in;
        padding-top: 64px;

        overflow-x: hidden;
        overflow-y: scroll;
    }

    &--open &__menu {
        transform: translateY(0);
    }

    &__menu-container {
        @include container;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__menu-items {
        margin-bottom: auto;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }

    &__bottom-button-group {
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 1px;
        }

        &:last-of-type {
            margin-bottom: 28px;
        }
    }

    &__bottom-button {
        @include basic-reset;
        height: $navigation-mobile-button-height;
        background-color: $color-grey-normalish;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        @include section-h-sides;

        &--quick-links {
            &::before {
                content: "";
                height: 16px;
                width: 16px;
                background-image: icon("link.svg");
                background-size: cover;
                margin-right: 8px;
            }
        }
    }

    &__bottom-button-content {
        position: fixed;
        background-color: $white;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transform: translateX(150%);
        transition: transform 0.2s ease-in;
        @include container;

        &--open {
            transform: translate(0);
        }
    }
    &__bottom-button-bar {
        height: $navigation-mobile-button-height;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__bottom-button-back {
        position: absolute;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: $section-outer-padding--mobile;

        @include tablet {
            padding-left: $section-outer-padding;
        }
    }
    &__bottom-button-back-icon {
        width: 10px;
        height: 12px;
    }

    &__menu-row {
        border-top: $navigation-mobile-border;
        height: $navigation-mobile-button-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include section-h-sides;

        &--height-auto {
            height: auto;
        }
    }

    &__cta {
        @include section-h-sides;
        margin-bottom: 20px;
    }

    &__search-content {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-top: $navigation-mobile-height;
        background-color: $white;
        width: 100%;
        height: 100%;
        transition: transform 0.4s ease-in;
        transform: translateY(-120%);

        &--open {
            transform: translateY(0);
        }
    }
}
