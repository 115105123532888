
            @import "@/styles/abstracts/all.scss";
          

.c-input-radio {
  &__option {
    border: 1px solid grey;
    background-color: $white;
    margin-bottom: 16px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    padding-left: 12px;
    display: flex;
    align-items: center;

    &--selected {
      border-color: $green;
    }
  }

  &__input {
    cursor: pointer;
    margin-right: 8px;
  }

  &__option {
    min-width: 100px;
  }

  &__option-label {
    cursor: pointer;
    padding-right: 16px;
    height: 48px;
    flex: 1;
    line-height: 48px;
  }

  &--has-inline-options {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
  }

  &--has-inline-options &__label {
    width: 100%;
  }
}
