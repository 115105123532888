
            @import "@/styles/abstracts/all.scss";
          

.c-search-page {
  @include full-grid;
  align-content: start;
  min-height: calc(100vh - $navigation-mobile-height);

  @include tablet-landscape {
    min-height: calc(100vh - $navigation-height - $navigation-top-height);
  }

  &__search-bar {
    grid-column: main-start / main-end;

    @include widescreen {
      margin-left: -35px;
      margin-right: -35px;
    }
  }

  &__main {
    grid-column: main-start / main-end;
  }

  &__refinements {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 24px;
    align-items: center;
  }
}
