
            @import "@/styles/abstracts/all.scss";
          

.l-overview-search-bar {
  display: none;

  @include tablet-landscape {
    display: flex;
    row-gap: 12px;
    column-gap: 16px;
    flex-wrap: wrap;
    padding-top: 32px;
  }

  &__input--search-box {
    flex-grow: 1;

    @include tablet {
      flex-grow: 0;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__reset-button {
    height: 44px;
    display: flex;
    align-items: center;
  }

  @include tablet-landscape {
    flex-wrap: nowrap;

    &__input--search-box {
      width: auto;
    }
  }

  &__label {
    display: block;
    margin-bottom: 4px;

    @include tablet-landscape {
      margin-bottom: 8px;
    }
  }
}
