/*
Cards

Style guide: 4-components.cards
 */

/*
Description Card

Markup:
<div class="description-card__columns" style="background: #DDD">
  <div class="description-card__column">
    <div class="description-card {{modifier_class}}">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h3 class="description-card__title">Card Title</h3>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
          </p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class="description-card__column">
    <div class="description-card {{modifier_class}}">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h3 class="description-card__title">Card Title</h3>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>Candy chocolate bar I love marshmallow pie sesame snaps sweet bonbon</p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class="description-card__column">
    <div class="description-card {{modifier_class}}">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h3 class="description-card__title">Card Title</h3>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
          </p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
</div>

Style guide: 4-components.cards.description-card
 */

@use "sass:math";

$types: "description-card", "usp-card";
$description-card__outer-padding--tiny: 1em;
$description-card__outer-padding--small: 1.5em;
$description-card__outer-padding--big: 2em;

@each $type in $types {
    .#{$type}__columns {
        @include basic-flex-box($breakpoint: "tablet-landscape");
        margin-bottom: -39px;
        margin-top: -39px;

        .#{$type}__column {
            @include basic-flex-item;
            padding-bottom: 39px;
            padding-top: 39px;
        }
    }

    .#{$type} {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        padding: 0 $description-card__outer-padding--tiny;
        width: 100%;

        .#{$type}__content-overlay {
            margin-left: -$description-card__outer-padding--tiny;
            margin-right: -$description-card__outer-padding--tiny;
        }

        @include mobile {
            padding-left: $description-card__outer-padding--small;
            padding-right: $description-card__outer-padding--small;

            .#{$type}__content-overlay {
                margin-left: -$description-card__outer-padding--small;
                margin-right: -$description-card__outer-padding--small;
            }
        }

        @include tablet {
            padding-left: $description-card__outer-padding--big;
            padding-right: $description-card__outer-padding--big;
            width: calc(8 / 12) * 100%;

            .#{$type}__content-overlay {
                margin-left: -$description-card__outer-padding--big;
                margin-right: -$description-card__outer-padding--big;
            }
        }

        @include tablet-landscape {
            padding-left: $description-card__outer-padding--small;
            padding-right: $description-card__outer-padding--small;
            width: 100%;

            .#{$type}__content-overlay {
                margin-left: -$description-card__outer-padding--small;
                margin-right: -$description-card__outer-padding--small;
            }
        }

        @include desktop {
            padding-left: $description-card__outer-padding--big;
            padding-right: $description-card__outer-padding--big;

            .#{$type}__content-overlay {
                margin-left: -$description-card__outer-padding--big;
                margin-right: -$description-card__outer-padding--big;
            }
        }

        .#{$type}__content-wrapper {
            background: $white;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-bottom: 16px;

            @include mobile {
                padding-bottom: 24px;
            }

            @include tablet {
                padding-bottom: 28px;
            }

            @include tablet-landscape {
                padding-bottom: 32px;
            }
        }

        .#{$type}__content-box {
            padding-left: 20px;
            padding-right: 20px;

            @include mobile {
                padding-left: 24px;
                padding-right: 24px;
            }

            @include tablet-landscape {
                padding-left: 16px;
                padding-right: 16px;
            }

            @include desktop {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        .#{$type}__content-box--header {
            padding-top: 11px;
            flex: 1 1 auto;

            @include tablet {
                padding-top: 15px;
            }

            @include tablet-landscape {
                padding-top: 19px;
            }

            @include desktop {
                padding-top: 23px;
            }
        }

        .usp-card__content-box--description {
            margin-bottom: 24px;
        }

        .#{$type}__title {
            margin: 0;
        }

        .#{$type}__description {
            padding-top: $padding-size;
        }

        .#{$type}__image {
            padding-top: math.div(224, 380) * 100%;
            position: relative;

            @include tablet {
                padding-top: math.div(276, 472) * 100%;
            }

            @include tablet-landscape {
                padding-top: math.div(185, 312) * 100%;
            }

            @include desktop {
                padding-top: math.div(248, 424) * 100%;
            }
        }
    }
}

.usp-card__content-box--header {
    padding-bottom: 3px;

    @include tablet-landscape {
        padding-bottom: 12px;
    }
}

.description-card__content-overlay {
    padding-bottom: 14px;
    padding-top: 13px;

    @include mobile {
        padding-bottom: 18px;
    }

    @include tablet {
        padding-bottom: 23px;
        padding-top: 17px;
    }

    @include tablet-landscape {
        padding-bottom: 14px;
        padding-top: 13px;
    }

    @include desktop {
        padding-bottom: 16px;
        padding-top: 17px;
    }
}

.usp-card__content-overlay {
    padding-top: 34px;

    @include tablet-landscape {
        padding-top: 29px;
    }
}

.description-card__content-box--footer {
    padding-top: 14px;

    @include mobile {
        padding-top: 18px;
    }

    @include widescreen {
        padding-top: 24px;
    }
}

/*
USP Card

Same look and feel as Description Card, but the order of elements is different

Markup:
<div class="usp-card__columns" style="background: #DDD">
  <div class="usp-card__column">
    <div class="usp-card">
      <div class="usp-card__content-wrapper">
        <div class="usp-card__content-box usp-card__content-box--header">
          <h3 class="usp-card__title">Card Title</h3>
        </div>
        <div class="usp-card__content-box usp-card__content-box--description">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
          </p>
        </div>
        <div class="usp-card__content-box">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
        <div class="usp-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="usp-card__column">
    <div class="usp-card">
      <div class="usp-card__content-wrapper">
        <div class="usp-card__content-box usp-card__content-box--header">
          <h3 class="usp-card__title">Card Title with a longer text than one line</h3>
        </div>
        <div class="usp-card__content-box usp-card__content-box--description">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
          </p>
        </div>
        <div class="usp-card__content-box">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
        <div class="usp-card__content-overlay">
          <div class="usp-card__image">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="usp-card__column">
    <div class="usp-card">
      <div class="usp-card__content-wrapper">
        <div class="usp-card__content-box usp-card__content-box--header">
          <h3 class="usp-card__title">Card Title</h3>
        </div>
        <div class="usp-card__content-box usp-card__content-box--description">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
          </p>
        </div>
        <div class="usp-card__content-box">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
        <div class="usp-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

Style guide: 4-components.cards.usp-card
*/

/*
Description Card - small card

A description card with a half padding from tablet-landscape for 5 items in a row

Markup:
<div class="description-card__columns has-small-cards" style="background: #DDD">
  <div class="description-card__column">
    <div class="description-card">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h4 class="description-card__title">Card Title</h4>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>Candy chocolate bar I love marshmallow pie sesame snaps sweet bonbon</p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class="description-card__column">
    <div class="description-card">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h4 class="description-card__title">Card Title</h4>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>Candy chocolate bar I love marshmallow pie sesame snaps sweet bonbon</p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class="description-card__column">
    <div class="description-card">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h4 class="description-card__title">Card Title</h4>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>Candy chocolate bar I love marshmallow pie sesame snaps sweet bonbon</p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class="description-card__column">
    <div class="description-card">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h4 class="description-card__title">Card Title</h4>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>Candy chocolate bar I love marshmallow pie sesame snaps sweet bonbon</p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class="description-card__column">
    <div class="description-card">
      <div class="description-card__content-wrapper">
        <div class="description-card__content-box description-card__content-box--header">
          <h4 class="description-card__title">Card Title</h4>
        </div>
        <div class="description-card__content-overlay">
          <div class="image is-16by9">
            <img
              class="object-fit"
              src="https://via.placeholder.com/1600x1200"
              alt="image title" />
          </div>
        </div>
        <div class="description-card__content-box description-card__content-box--description">
          <p>Candy chocolate bar I love marshmallow pie sesame snaps sweet bonbon</p>
        </div>
        <div class="description-card__content-box description-card__content-box--footer">
            <a class="button is-full-width"
               href="#">Read More</a>
        </div>
      </div>
    </div>
  </div>
</div>

Style guide: 4-components.cards.description-card-small
 */

.description-card__columns.has-small-cards {
    @include basic-flex-box($breakpoint: "tablet");
    @include tablet {
        flex-wrap: wrap;

        .description-card__column {
            flex: none;
            width: 33%;

            @include widescreen {
                width: 20%;
            }
        }

        .description-card {
            width: 100%;
        }

        .description-card__content-box {
            padding-left: 24px;
            padding-right: 24px;

            @include tablet {
                height: calc(4 * 24px);
            }

            @include tablet-landscape {
                height: calc(3 * 24px);
            }

            @include widescreen {
                padding-left: 13px;
                padding-right: 13px;
            }
        }

        .description-card {
            padding-left: $description-card__outer-padding--small * 0.5;
            padding-right: $description-card__outer-padding--small * 0.5;
        }

        .description-card__content-overlay {
            margin-left: -$description-card__outer-padding--small * 0.5;
            margin-right: -$description-card__outer-padding--small * 0.5;
            padding-bottom: 18px;
            padding-top: 17px;

            @include widescreen {
                padding-top: 22px;
                padding-bottom: 12px;
            }
        }
    }
}

/*
Related Card

Markup:
<section class="section" style="background: grey; z-index: -2">
  <div class="container">
    <div class="related-card__heading">
      <h2>Related card heading</h2>
    </div>
    <div class="related-card__columns">
      <div class="related-card__column">
        <div class="related-card">
            <div class="related-card__image">
              <div class="image">
                <img
                  class="object-fit"
                  src="https://via.placeholder.com/1600x1200"
                  alt="image title" />
              </div>
            </div>
            <div class="related-card__content">
              <h6 class="is-text">00 month 2020</h6>
              <h3 class="related-card__content-title">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              </h3>
              <p class="related-card__content-description">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
              </p>
            </div>
            <div class="related-card__footer">
              <a class="text-button__uppercase"
                href="#">Read More</a>
            </div>
        </div>
      </div>
      <div class="related-card__column">
        <div class="related-card">
            <div class="related-card__image">
              <div class="image">
                <img
                  class="object-fit"
                  src="https://via.placeholder.com/1600x1200"
                  alt="image title" />
              </div>
            </div>
            <div class="related-card__content">
              <h6 class="is-text">00 month 2020</h6>
              <h3 class="related-card__content-title">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              </h3>
              <p class="related-card__content-description">
                Lorem ipsum dolor sit amet.
              </p>
            </div>
            <div class="related-card__footer">
              <a class="text-button__uppercase"
                href="#">Read More</a>
            </div>
        </div>
      </div>
      <div class="related-card__column">
        <div class="related-card">
            <div class="related-card__image">
              <div class="image">
                <img
                  class="object-fit"
                  src="https://via.placeholder.com/1600x1200"
                  alt="image title" />
              </div>
            </div>
            <div class="related-card__content">
              <h6 class="is-text">00 month 2020</h6>
              <h3 class="related-card__content-title">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              </h3>
            </div>
            <div class="related-card__footer">
              <a class="text-button__uppercase"
                href="#">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

Style guide: 4-components.cards.related-card
*/

.related-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 35px;
    position: relative;

    @include tablet-landscape {
        padding-bottom: 58px;
        padding-left: 2 * $padding-size;
        padding-right: 2 * $padding-size;
    }

    &::before {
        background: $white;
        content: "";
        position: absolute;
        bottom: 0;
        left: -$section-outer-padding--mobile;
        right: -$section-outer-padding--mobile;
        top: 120px;
        z-index: -1;

        @include tablet-landscape {
            left: 0;
            right: 0;
            top: 79px;
        }

        @include widescreen {
            top: 112px;
        }
    }

    &__heading {
        margin-bottom: 10px;

        @include tablet-landscape {
            margin-bottom: 22px;
        }
    }

    &__columns {
        @include basic-flex-box($breakpoint: "tablet-landscape");
    }

    &__column {
        padding: 28px $padding-size;

        @include tablet-landscape {
            flex: none;
            width: 33.3333%;
        }
    }

    &__image {
        .image {
            padding-top: math.div(230, 380) * 100%;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    @include tablet-landscape {
        .image {
            padding-top: math.div(224, 376) * 100%;
        }
    }

    &__content {
        flex: 1;
        min-height: max-content;
        padding-top: 25px;

        @include tablet-landscape {
            padding-top: 14px;
        }
    }

    &__content-description {
        padding: 6px 0;
    }

    &__footer {
        padding-top: 6px;
    }
}

/*
Simple Card

Markup:
<div>
  <div class="simple-card__heading">
    <h2>Simple card title</h2>
  </div>
  <div class="simple-card__columns">
    <div class="simple-card__column">
      <div class="simple-card">
        <h4>Card title</h4>
        <p class="simple-card__description">
          Biscuit cupcake chocolate cake tart sweet. Bonbon jelly beans halvah sweet roll I love.
        </p>
        <a class="text-button__uppercase" href="#">Read More</a>
      </div>
    </div>
    <div class="simple-card__column">
      <div class="simple-card">
        <h4>Card title</h4>
        <p class="simple-card__description">
          Biscuit cupcake chocolate cake tart sweet. Bonbon jelly beans halvah sweet roll I love.
        </p>
        <a class="text-button__uppercase" href="#">Read More</a>
      </div>
    </div>
    <div class="simple-card__column">
      <div class="simple-card">
        <h4>Card title</h4>
        <p class="simple-card__description">
          Biscuit cupcake chocolate cake tart sweet. Bonbon jelly beans halvah sweet roll I love.
        </p>
        <a class="text-button__uppercase" href="#">Read More</a>
      </div>
    </div>
    <div class="simple-card__column">
      <div class="simple-card">
        <h4>Card title</h4>
        <p class="simple-card__description">
          Biscuit cupcake chocolate cake tart sweet. Bonbon jelly beans halvah sweet roll I love.
        </p>
        <a class="text-button__uppercase" href="#">Read More</a>
      </div>
    </div>
    <div class="simple-card__column">
      <div class="simple-card">
        <h4>Card title</h4>
        <p class="simple-card__description">
          Biscuit cupcake chocolate cake tart sweet. Bonbon jelly beans halvah sweet roll I love.
        </p>
        <a class="text-button__uppercase" href="#">Read More</a>
      </div>
    </div>
  </div>
</div>

Style guide: 4-components.cards.simple-card
 */

.simple-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top: 1px solid $black;
    padding-bottom: 10px;
    padding-top: 10px;

    &__visual {
        width: 100%;
        aspect-ratio: 16/9;
        position: relative;
        margin-bottom: 10px;
    }

    &__heading {
        padding-bottom: 40px;
    }

    &__columns {
        @include basic-flex-box($breakpoint: "none", $multiline: true);
    }

    &__column {
        @include basic-flex-item;
        flex: none;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;

        @include tablet {
            width: math.div(100%, 3) * 1;

            @include with-count(1) {
                width: 100%;
            }

            @include with-count(2) {
                width: 50%;
            }
        }

        @include tablet-landscape {
            width: 25%;

            @include with-count(1) {
                width: 50%;
            }

            @include with-count(2) {
                width: 50%;
            }

            @include with-count(3) {
                width: math.div(100%, 3) * 1;
            }

            @include with-count(4) {
                width: 25%;
            }

            @include with-count(5) {
                width: math.div(100%, 3) * 1;
            }

            @include with-count(6) {
                width: math.div(100%, 3) * 1;
            }
        }
    }

    &__description {
        padding-bottom: 8px;
        padding-top: 8px;
    }

    &--has-visual {
        border-top: none;
        padding-top: 0px;
    }
}

/*
Featured Card

Markup:
<section class="section" style="background: grey">
  <div class="featured-card__heading">
    <h2>Featured card heading</h2>
  </div>
  <div class="featured-card__columns">
    <div class="featured-card__column">
      <div class="featured-card">
        <div class="featured-card__content">
          <h3>Card title</h3>
          <p class="featured-card__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor.
          </p>
          <a class="text-button__uppercase" href="#">Read More</a>
        </div>
        <div class="featured-card__image">
          <img
            class="object-fit"
            src="https://via.placeholder.com/1600x1200"
            alt="image title" />
        </div>
      </div>
    </div>
    <div class="featured-card__column">
      <div class="featured-card">
        <div class="featured-card__content">
          <h3>Card title</h3>
          <p class="featured-card__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <a class="text-button__uppercase" href="#">Read More</a>
        </div>
        <div class="featured-card__image">
          <img
            class="object-fit"
            src="https://via.placeholder.com/1600x1200"
            alt="image title" />
        </div>
      </div>
    </div>
    <div class="featured-card__column">
      <div class="featured-card">
        <div class="featured-card__content">
          <h3>Card title</h3>
          <p class="featured-card__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor.
          </p>
          <a class="text-button__uppercase" href="#">Read More</a>
        </div>
        <div class="featured-card__image">
          <img
            class="object-fit"
            src="https://via.placeholder.com/1600x1200"
            alt="image title" />
        </div>
      </div>
    </div>
  </div>
</section>

Style guide: 4-components.cards.featured-card
*/

.featured-card {
    background: $white;
    display: flex;
    height: 100%;
    min-height: 200px;
    justify-content: space-between;

    @include tablet-landscape {
        min-height: 224px;
    }

    &__image {
        flex: none;
        position: relative;
        width: 30%;
        background: $purple;
        mask-image: image("leaf-right.svg");
        mask-position: left -108px;
        mask-repeat: no-repeat;
        mask-size: 378px;
        display: none;

        @include tablet {
            display: block;
        }

        @include tablet-landscape {
            display: none;
        }

        @include widescreen {
            display: block;
        }
    }

    &__heading {
        margin-bottom: 24px;
    }

    &__columns {
        @include basic-flex-box($breakpoint: "tablet-landscape");
    }

    &__column {
        padding: 20px $padding-size;

        @include tablet-landscape {
            flex: none;
            width: 33.3333%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        padding-left: 2 * $padding-size;
        padding-right: 2 * $padding-size;
        padding-top: 24px;
        word-break: break-word;

        @include tablet-landscape {
            padding-bottom: 32px;
        }
    }

    &__description {
        flex-grow: 1;
        padding-bottom: 6px;
        padding-top: 8px;
        word-break: normal;
    }
}

/*
Notification Card

Markup:
<div style="width: 424px">
  <div class="notification-card">
    <h6 class="notification-card__tag has-black-text">Tag</h6>
    <h5>Title</h5>
    <p class="notification-card__description">Notification card text</p>
    <a href="#" class="text-button__uppercase has-white-text">
      Read more
    </a>
  </div>
</div>

Style guide: 4-components.cards.notification-card
*/

.notification-card {
    background: $orange;
    color: $white;
    padding: 2 * $padding-size;

    &__link {
        &:link,
        &:active,
        &:visited {
            display: inline-block;
            margin-top: $padding-size;
            font-size: 12px;
            font-family: $micro-font-stack;
            font-weight: 600;
            text-transform: uppercase;
            color: white;
        }
    }

    &__tag {
        padding-bottom: 6px;
    }

    &__description {
        padding-top: 4px;
        padding-bottom: 8px;
    }
}

.detail-card {
    display: flex;

    &__visual {
        $figure-width: 100 * 0.125 * 3%;
        position: relative;
        width: $figure-width;
        padding-top: math.div($figure-width, 16) * 9;
        overflow: hidden;
        margin-right: $padding-size;
        background-size: cover;
    }

    &__content {
        flex: 1;
        display: flex;
        align-items: center;
    }
}

$offset-image: 56px;
$overflow-image-d: 32px;
$visual-width-d: 38%;
.portret-card {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    padding-right: $section-outer-padding--mobile;
    padding-bottom: $padding-size * 5;
    padding-left: $section-outer-padding--mobile;
    margin-top: $offset-image;

    @include tablet {
        align-items: flex-start;
        padding: 0;
        padding-right: $overflow-image-d;

        margin-top: $overflow-image-d;
        margin-bottom: $overflow-image-d;
    }

    &__titles {
        flex: 1 1 calc(100% - 100px);
        padding-right: $section-outer-padding--mobile;
        padding-top: $section-outer-padding--mobile;

        @include tablet {
            flex: 0 0 (100% - $visual-width-d);

            padding: $padding-size * 2;
        }
    }

    &__titles-header {
        display: block;
        margin-bottom: 8px;
    }

    &__titles-content {
        display: none;

        @include tablet {
            display: block;
        }
    }

    &__visual {
        flex: 0 0 100px;
        margin-top: -$offset-image;

        @include tablet {
            flex: 0 0 $visual-width-d;
            margin-top: -$overflow-image-d;
            margin-bottom: -$overflow-image-d;
        }
    }

    &__content {
        @include tablet {
            display: none;
        }
    }

    &__image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 137.5%;
    }

    &__image {
        position: absolute;
        width: 100%;
    }
}

.landscape-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    &__visual {
        padding: 0 $section-outer-padding--mobile;
        background: linear-gradient(
            to bottom,
            rgba($grey-very-light, 1) 50%,
            rgba($white, 1) 50%
        );

        @include tablet {
            padding: 0 20px;
        }
    }

    &__image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: math.div(100%, 16) * 9;
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        background-color: $white;
        padding: $section-outer-padding--mobile;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include tablet {
            padding: 20px;
        }
    }

    &__title {
        display: block;
        margin-bottom: 8px;
    }

    &__cta {
        padding-top: 16px;
        margin-top: auto;
    }
}

.contact-cards {
    display: inline-grid;
    grid-template-columns: 1fr;
    row-gap: $padding-size * 4;

    @include tablet {
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: $gutter;
    }

    @include tablet-landscape {
        grid-template-columns: repeat(3, 1fr);
    }

    &__item {
        @include tablet {
            display: flex;
        }
    }

    &__visual {
        @include tablet {
            margin-right: $padding-size * 2;
        }
    }

    &__icon {
        height: 50px;
        width: 50px;
    }

    &__content {
        flex: 1;

        & > *:not(:last-child) {
            margin-bottom: $padding-size;
        }
    }

    &__cta {
        @include flex-percentage(3, 2);

        @include tablet {
            width: fit-content;
            min-width: 72%;
        }

        @include tablet-landscape {
            min-width: 82%;
        }

        @include widescreen {
            min-width: 56%;
        }
    }
}

.c-card {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: $white;
    width: 100%;

    &--green-light {
        background-color: $green-light;
    }

    &--padding-big {
        padding-top: 24px;
        padding-bottom: 24px;
        background-color: $white;
    }

    &--green-light {
        background-color: $green-light;
    }

    &--padding-big {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24pxpx;
        padding-left: 20px;

        @include tablet-landscape {
            padding-top: 50px;
            padding-right: 40px;
            padding-bottom: 50px;
            padding-left: 40px;
        }
    }

    &--has-border {
        border: $border-medium;
    }

    &--has-shadow {
        box-shadow: 0px 4px 15px 0px rgba(0, 26, 11, 0.05);
    }

    &__logo {
        height: 60px;
        margin-bottom: 12px;

        & > svg {
            height: 100%;
        }
    }

    &--has-border-radius {
        border-radius: 6px;
    }

    &--has-shadow {
        box-shadow: 0px 4px 15px 0px rgba(0, 26, 11, 0.05);
    }

    &__logo {
        height: 60px;
        margin-bottom: 12px;

        & > svg {
            height: 100%;
        }
    }

    &__buttons {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 8px;
    }
}

.c-overview-result {
    position: relative;
    display: flex;
    column-gap: 8px;
    width: 100%;
    overflow: hidden;

    @include tablet-landscape {
      @include add-hover-animation;

        border-radius: 6px;
        border: $border-medium;
        flex-direction: column;
        row-gap: 0px;
    }

    &:hover &__visual {
        @include tablet-landscape {
            transform: scale(102%);
        }
    }

    &__visual {
        $width-c-overview-result__visual--mobile: 124px;
        flex: 0 0 $width-c-overview-result__visual--mobile;
        height: 0px;
        padding-top: calc($width-c-overview-result__visual--mobile / 118 * 81);
        align-self: flex-start;
        position: relative;
        background-color: $grey;
        z-index: 1;

        @include tablet {
            $width-c-overview-result__visual--tablet: 248px;
            flex: 0 0 $width-c-overview-result__visual--tablet;
            padding-top: calc(
                $width-c-overview-result__visual--tablet / 118 * 81
            );
        }

        @include tablet-landscape {
            aspect-ratio: auto;
            width: 100%;
            height: 0px;
            padding-top: 50%;
            flex: 0;
            transition: all 0.3s ease-in-out;
            transform: scale(100%);
        }
    }

    &__content {
        position: relative;
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        z-index: 3;

        @include tablet-landscape {
            background-color: white;
            justify-content: flex-start;
            flex: initial;
            width: 100%;
            padding-top: 16px;
            padding-right: 24px;
            padding-bottom: 16px;
            padding-left: 24px;
        }
    }

    &__title {
        margin-bottom: 8px;
        font-family: $title-font-stack;
        font-weight: 400;
        font-size: 15px;

        @include limit-lines(3);

        @include tablet {
            font-size: 22px;
        }

        @include tablet-landscape {
            font-size: 22px;
        }

        &--featured {
            font-size: 22px;

            @include tablet {
                font-size: 28px;
            }

            @include tablet-landscape {
                font-size: 22px;
            }
        }

        @include tablet-landscape {
            margin-bottom: 16px;
        }
    }

    &__body {
        display: none;

        @include tablet-landscape {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
        }
    }

    &__icon {
        display: none;
    }

    &__tags {
        display: none;

        @include tablet-landscape {
            display: block;
            margin-bottom: 16px;
        }
    }

    @include until-tablet-landscape {
        &--is-featured {
            aspect-ratio: 2/1;
        }

        &--is-featured &__visual {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &--is-featured &__content {
            flex: 0 0 100%;
            color: $white !important;
            padding-top: 12px;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 24px;
            justify-content: flex-end;
            background-image: linear-gradient(
                rgba($black, 0.2) 24%,
                rgba($black, 0.7)
            );
        }

        &--is-featured &__icon {
            display: block;
            height: 32px;
            width: 32px;
            background-color: $orange;
            position: absolute;
            bottom: 0;
            right: 0;
            margin-right: -1px;
            margin-bottom: -1px;
            background-image: icon("fas-fa-arrow-right.svg");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 12px;
        }
    }
}

.c-card-article-small {
    display: flex;
    column-gap: 16px;
    align-items: center;

    &__visual {
        flex: 0 0 128px;
    }

    &__title {
        margin-bottom: 10px;
        font-family: $title-font-stack;
        font-weight: 400;
        font-size: 16px;

        @include limit-lines(3);

        @include tablet {
            font-size: 18px;
        }

        @include tablet-landscape {
            font-size: 20px;
        }
    }

    &__pills {
        display: none;
        margin-bottom: 10px;

        @include tablet {
            display: block;
        }
    }
}

.c-card-general {
    border: 1px solid $grey;
    border-radius: 6px;
    background-color: $white;

    &__content {
        padding-right: 16px;
        padding-left: 16px;

        @include tablet-landscape {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}

.c-card-solid {
    background-color: $white;
    padding: 32px 24px;
    border-radius: 6px;
    box-shadow: $card-shadow;
    display: flex;
    flex-direction: column;

    @include tablet {
        align-items: flex-start;
    }

    &--green-light {
        background-color: $green-light;
        box-shadow: none;
    }
}

.c-card-image {
    display: block;
    position: relative;
    aspect-ratio: 245/278;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    @include tablet-landscape {
        aspect-ratio: 210/260;
    }

    &:hover &__image img {
        transform: scale(1.02);
    }

    &__image img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        z-index: 2;
        padding: 40px 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        background-image: linear-gradient(
            0deg,
            rgba($black, 0.9) 5.51%,
            rgba($black, 0) 68.15%
        );
        flex-direction: column;
    }
}

.l-card-grid {
    display: grid;
    row-gap: 20px;

    @include tablet-landscape {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 12px;
    }
}

.c-card-numbers {
    border: $border-standard-2023;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    &__head {
        padding-top: 16px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 20px;
        flex-grow: 1;
        align-content: flex-start;

        display: grid;
        grid-template-columns: auto auto;
        align-items: end;
        row-gap: 4px;
    }

    &__title {
        grid-column: 1 / span 1;
    }

    &__subtitle {
        grid-column: 2 / span 1;
        justify-self: end;
    }

    &__caption {
        grid-column: 1 / -1;
    }

    &__body {
        background-color: $green-light;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__foot {
        height: 40px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__value-right {
        margin-left: auto;
        display: flex;
        column-gap: 16px;
    }

    &__group {
        display: flex;
        align-items: flex-end;
        column-gap: 8px;
    }

    &__icon {
        margin-bottom: 3px;
    }
}

.contact-card {
    background-color: $green-100;
    border: 1px solid $green-200;
    border-radius: $border-radius-standard;
    color: $green-main;
    display: grid;
    align-content: stretch;
    width: 100%;

    @include tablet-landscape {
        grid-template-columns: 4fr 6fr;

        &--no-image {
            grid-template-columns: 12fr;
        }
    }

    &__content {
        padding: 1.5rem;
    }

    &__image {
        @include tablet-landscape {
            display: block;
        }

        display: none;
        overflow: hidden;
        object-fit: cover;
        position: relative;
    }

    &__name {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__function {
        font-size: 1.125rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    &__email,
    &__phoneNumber {
        font-size: 0.875rem;
    }

    &__linkedin {
        color: $green;
        font-weight: 600;
        margin-top: 1.5rem;
        display: block;
        display: flex;
        align-items: center;
        gap: 6px;
    }
}

.c-card-meeting {
    display: flex;

    &__visual {
        width: 90px;
        display: flex;

        @include tablet {
            width: 140px;
        }
    }

    &__visual-container {
        position: relative;
        width: 100%;
        padding-top: calc(100% / 2 * 3);
        overflow: hidden;
        border-top-left-radius: $border-radius-standard;
        border-bottom-left-radius: $border-radius-standard;

        @include tablet {
            padding-top: 56.25%;
        }
    }

    &__content {
        padding: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        flex: 1;
        justify-content: space-between;

        border-top: $border-standard-2023;
        border-right: $border-standard-2023;
        border-bottom: $border-standard-2023;
        border-top-right-radius: $border-radius-standard;
        border-bottom-right-radius: $border-radius-standard;

        @include tablet {
            flex-direction: row;
            align-items: center;
        }
    }

    &__description {
        display: flex;
        column-gap: 8px;
        align-items: center;
        svg {
            transform: translateY(1px);
        }
    }
}

.c-card-imagery {
    @include basic-reset;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet-landscape {
        flex-direction: row;

        @include add-hover-animation;
        border-radius: $border-radius-standard;
    }

    &__visual {
        width: 100%;

        @include tablet-landscape {
            flex: 0 0 35%;
        }

        @include widescreen {
            flex: 0 0 32%;
        }
    }

    &__visual-container {
        position: relative;
        width: 100%;
        padding-top: calc(100% / 331 * 166);
        overflow: hidden;
        border-top-left-radius: $border-radius-standard;
        border-top-right-radius: $border-radius-standard;
        overflow: hidden;

        @include tablet-landscape {
            padding-top: calc(100% / 325 * 198);
            border-top-right-radius: 0%;
            border-bottom-left-radius: $border-radius-standard;
        }
    }

    &__content {
        position: relative;
        padding: 16px;
        display: flex;
        flex-direction: column;
        border-left: $border-standard-2023;
        border-right: $border-standard-2023;
        border-bottom: $border-standard-2023;
        border-bottom-left-radius: $border-radius-standard;
        border-bottom-right-radius: $border-radius-standard;
        flex: 1 1 auto;

        @include tablet-landscape {
            padding: 16px 24px;
            pointer-events: none;
            align-items: flex-start;

            border-left: none;
            border-top: $border-standard-2023;
            border-bottom-left-radius: 0;
            border-top-right-radius: $border-radius-standard;
        }
    }

    &__tags {
        display: none;

        @include tablet-landscape {
            display: flex;
            flex-wrap: wrap;
            column-gap: 8px;
            row-gap: 8px;
            margin-bottom: 16px;
        }
    }

    &__title {
        margin-bottom: 16px;

        @include tablet-landscape {
            pointer-events: auto;
        }
    }

    &__description {
        display: none;

        @include tablet-landscape {
            display: block;

            @include limit-lines(2);
        }
    }

    &__link {
        display: none;

        @include tablet-landscape {
            margin-top: auto;
            display: block;
            pointer-events: auto;
        }
    }

    &__details {
        display: flex;
        column-gap: 8px;
        align-items: center;

        @include tablet-landscape {
            position: absolute;
            top: 16px;
            right: 24px;
        }
    }
}

.c-expandable-card {

    &__content {
        display: none;
        padding: 16px;
        border-top: $border-standard-2023;
        border-bottom: $border-standard-2023;
    }

    &--expanded {
      height: 400px;
    }

    &--expended &__subtitle {

      &---modifier {
        display: none;
      }
    }
}
