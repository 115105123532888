
            @import "@/styles/abstracts/all.scss";
          

.c-switcher-links-list {
  display: flex;
  gap: 1rem;

  &__item {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;

    &--active {
      font-weight: bold;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__link {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s;
  }

  &__icon {
    width: 24px;
    height: 18px;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include tablet-landscape {
      order: 1;
    }
  }

  &__item--nl &__icon {
    filter: grayscale(1);
    background-image: icon("flags/flag-nl.svg");
  }

  &__item--en &__icon {
    filter: grayscale(1);
    background-image: icon("flags/flag-en.svg");
  }

  &__item--de &__icon {
    filter: grayscale(1);
    background-image: icon("flags/flag-de.svg");
  }

  &__item--active &__icon {
    filter: none;
  }

  &--column {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &--column &__link {
    display: flex;
    align-items: center;
    padding-left: 0px;
    column-gap: 8px;
  }
}
