@use "sass:math";

.search-box {
    position: relative;
    min-height: 44px;
    height: fit-content;

    font-family: $title-font-stack;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 14px;
        width: 14px;
        background-image: icon("search-new.svg");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 2;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 16px;
    }

    &__container {
        position: relative;
    }

    &__clear-button {
        @include tablet-landscape {
            color: $grey-dark;
            background: transparent;
            border: none;
            text-align: left;
            padding: 0.5em 2 * $padding-size;
            padding-left: 0;
            font-weight: normal;
            svg {
                display: none;
            }
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &__input {
        width: 100%;
        height: $input-height;
        padding-left: 40px;
        padding-right: 60px;
        border: $border-standard-2023;
        background-color: $white;
        font-weight: 300;
        border-radius: 6px !important;
        transition: padding-left 0.1s ease-out;

        &::placeholder {
            color: #ccc;
        }

        &--dark {
            background: $grey-very-light;
            @include tablet-landscape {
                height: 32px;
                padding-top: 4px;
                padding-bottom: 4px;
                + .search-box__reset-btn {
                    height: 32px;
                    width: 32px;
                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    &__reset-btn {
        display: none;
        cursor: pointer;
        background-image: icon("close.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
        position: absolute;
        height: 100%;
        width: 40px;
        top: 0;
        right: 0;
    }

    &__input:valid + &__reset-btn {
        display: flex;
    }

    &__buttons {
        flex-wrap: wrap;
        width: calc(100% + 24px);
        margin-right: -24px;
        @include tablet-landscape {
            padding-top: 10px;
            display: flex;
        }
    }

    &__button-holder {
        flex: 1 0 43%;
        margin-right: 24px;

        display: inline-block;

        * {
            width: 100%;
        }
        &--mobile-hidden {
            @include until-tablet-landscape {
                display: none;
            }
        }
    }

    &--big &__input {
        min-height: 72px;
        font-size: 28px;
        padding-left: 16px;
        padding-right: 42px;
        line-height: 28px;
        font-weight: 500;

        @include tablet-landscape {
            min-height: 104px;
            font-size: 40px;
            padding-left: 35px;
            line-height: 40px;
        }
    }

    &--big &__reset-btn {
        background-size: 16px;
        margin-right: 16px;
        width: 16px;

        @include tablet-landscape {
            width: 24px;
            margin-right: 35px;
            background-size: 24px;
        }
    }

    &--big::before {
        height: 26px;
        width: 26px;

        @include tablet-landscape {
            height: 32px;
            width: 32px;
            margin-left: 32px;
        }
    }

    &--hide-magnifier::before {
        opacity: 0;
    }

    &--big &__input:not(:placeholder-shown) {
        padding-left: 64px;

        @include tablet-landscape {
            padding-left: 90px;
        }
    }
}

// Hides the blue "X" from input fields.

// IE
input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

// Webkit (chrome etc)
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.search-autocomplete {
    position: relative;
    &__list {
        position: absolute;
        top: 0;
        left: -1.5rem;
        width: calc(100% + 60px + 1.5rem);
        padding-left: 1.5rem;
        padding-right: 60px;
        padding-bottom: 20px;
        background: $white;
        z-index: 3;
    }
    &__item {
        border-bottom: solid $grey 1px;
        &:last-child {
            border-bottom: none;
        }
    }
    &__link {
        padding: 4px 0;
        display: block;
        width: 100%;
        text-decoration: none;
        cursor: pointer;
        &:hover,
        &:focus {
            background: $grey;
        }
    }
    &--small {
        ul {
            padding: 0;
            left: 0;
            width: 100%;
            li {
                padding: 0;
                a {
                    padding: 6px 8px;
                }
            }
        }
    }
}
