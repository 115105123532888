.card-row {
  @include full-grid-with-column-gap;

  & {
    position: relative;
    z-index: 2;
    overflow-x: auto;
    scrollbar-width: none;
  }

  /* TODO: set default margins when not used for hover-over-header */
  &--hover-over-header {
    margin-top: -60px;
  }

  &__container {
    grid-column: 2 / span 12;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--column-gap);

    @include widescreen {
      grid-column: 3 / span 10;
    }
  }
}

.card-blank {
  border: $border-standard-2023;
  border-radius: var(--border-radius-standard);
  display: flex;
  background: #ffffff;
  padding: 1.5rem 1rem;
  width: 320px;

  @include tablet-landscape {
    width: auto;
  }

  &__icon-usp {
    display: flex;
    justify-content: center;
    padding-top: 0.2rem;
    margin-right: 0.5rem;
  }

  &__description {
    display: none;

    @include tablet {
      display: block;
    }
  }
}
