/*
Pagination

Markup:
<div class="pagination">
    <ul class="pagination__list">
        <li class="pagination__slot  pagination__slot--first">
            <a aria-label="First" class=" pagination__link">‹‹</a>
        </li>
        <li class="pagination__slot  pagination__slot--previous">
            <a aria-label="Previous" class="pagination__link">‹</a>
        </li>
        <li class="pagination__slot">
            <a class=" pagination__link">1</a>
        </li>
        <li class="pagination__slot">
            <a class=" pagination__link">2</a>
        </li>
        <li class="pagination__slot  pagination__slot--active">
            <a class="pagination__link">3</a>
        </li>
        <li class="pagination__slot">
            <a class=" pagination__link">4</a>
        </li>
        <li class="pagination__slot  pagination__slot--next">
            <a aria-label="Next" class="pagination__link">›</a>
        </li>
        <li class=" pagination__slot  pagination__slot--last">
            <a aria-label="Last" class=" pagination__link">››</a>
        </li>
    </ul>
</div>

Style guide: 4-components.pagination
*/

.pagination {
  width: 100%;
  position: relative;

  &__list {
      display: flex;
      justify-content: center;
  }

  $s: 40px;

  &__slot {
      height: $s;
      width: $s;
      text-align: center;

      &--previous,
      &--next {
          flex: 1;

          display: flex;
          column-gap: 12px;
      }

      &--active {
          a {
              font-weight: bold;
              color: $orange;

              &:focus {
                  color: $orange;
              }
          }
      }

      &--disabled {
          opacity: 0.2;
          pointer-events: none;
      }
  }

  &__link:hover {
      color: $orange;
  }

  &__link {
      display: block;
      width: 100%;
      height: 100%;
      line-height: $s;
      text-decoration: none;
      color: inherit;
      align-items: center;

      &:hover {
          text-decoration: underline;
          color: $orange;
      }

      &--previous,
      &--next {
          display: flex;
          column-gap: 12px;
      }

      &--next {
          justify-content: flex-end;
      }

      &--previous {
          justify-content: flex-start;
      }
  }

  &--is-centered &__slot--next {
      text-align: left;
      margin-left: 20px;
      padding-left: 20px;
  }
  &--is-centered &__slot--previous {
      text-align: right;
      margin-right: 20px;
      padding-right: 20px;
  }

  &--is-centered &__link--previous {
      justify-content: flex-end;
  }

  &--is-centered &__link--next {
      justify-content: flex-start;
  }

  &--is-green &__slot--active {
      a {
          color: $green;

          &:focus {
              color: $green;
          }
      }
  }

  &--is-green &__link:hover {
      color: $green;
  }
}
