
            @import "@/styles/abstracts/all.scss";
          

.c-close-button {
  @include basic-reset;
  display: flex;
  align-items: center;
  font-family: $title-font-stack;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
  font-weight: 200;
  align-items: center;
  transition: all 0.2s ease;

  @include on-event {
    text-decoration: underline;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  &--big {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &--grey-light {
    color: $grey-light;
  }
}
